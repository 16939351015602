<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
            <span>
              <em>当前位置：</em>
              <a href="javascript:;">题库管理</a>
              <i>></i>
              <a href="javascript:;">题库管理</a>
              <i>></i>
              <a href="javascript:;" @click="toQuestionList">题目管理</a>
              <i>></i>
              <a href="javascript:;" class="cur-a">{{ formData.questionId ? '修改题目' : '新增题目'}}</a>
            </span>
      </div>
      <div class="framePage-body">
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-form ref="formData" :inline="true" :model="formData" label-width="100px" size="small" :rules="formDataRules">
              <el-form-item label="题目类型" prop="questionType" class="form-item">
                <el-select
                    v-model="formData.questionType"
                    placeholder="请选择题目类型"
                    :disabled="formData.questionId"
                >
                  <el-option
                      v-for="item in QuestionTypeList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="知识点" prop="knowledgeId" class="form-item">
                <el-cascader
                    v-model="formData.knowledgeId"
                    :options="knowledgeIdList"
                    :props="{
                      checkStrictly: true,
                      emitPath: false,
                      label:'knowledgeName',
                      value:'knowledgeId',
                      children:'children'
                    }"
                    filterable
                    placeholder="请选择知识点"
                    style="width: 400px;">
                </el-cascader>
              </el-form-item>
              <el-form-item label="难度" prop="difficultyLevel" class="form-item">
                <el-select
                    v-model="formData.difficultyLevel"
                    placeholder="请选择难度"
                >
                  <el-option
                      v-for="item in DegreeDifficultyList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-form>
            <SingleChoiceQuestions :stu="formData.stu" :formData="formData" v-if="formData.questionType == 10"/>
            <MultipleChoiceQuestions :stu="formData.stu" :formData="formData" v-if="formData.questionType == 20"/>
            <Completion :stu="formData.stu" :formData="formData" v-if="formData.questionType == 40"/>
            <Judge :stu="formData.stu" :formData="formData" v-if="formData.questionType == 30"/>
            <ShortAnswer :stu="formData.stu" :formData="formData" v-if="formData.questionType == 50"/>
            <CalculationQuestions :stu="formData.stu" :formData="formData" v-if="formData.questionType == 60"/>
            <EssayQuestion :stu="formData.stu" :formData="formData" v-if="formData.questionType == 64"/>
            <CaseAnalysisQuestionsReal :stu="formData.stu" :formData="formData" v-if="formData.questionType == 68"/>
            <CombinationQuestion :stu="formData.stu" :formData="formData" v-if="formData.questionType == 80"/>
            <CaseAnalysisQuestions :stu="formData.stu" :formData="formData" v-if="formData.questionType == 70"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// 单选题
import SingleChoiceQuestions from "@/views/inkQuestionBank/QuestionBankManage/questionType/SingleChoiceQuestions";
// 多选题
import MultipleChoiceQuestions from "@/views/inkQuestionBank/QuestionBankManage/questionType/MultipleChoiceQuestions";
// 填空题
import Completion from "@/views/inkQuestionBank/QuestionBankManage/questionType/Completion";
// 判断题
import Judge from "@/views/inkQuestionBank/QuestionBankManage/questionType/Judge";
// 简答题
import ShortAnswer from "@/views/inkQuestionBank/QuestionBankManage/questionType/ShortAnswer";
// 计算题
import CalculationQuestions from "@/views/inkQuestionBank/QuestionBankManage/questionType/CalculationQuestions";
// 论述题
import EssayQuestion from "@/views/inkQuestionBank/QuestionBankManage/questionType/EssayQuestion";
// 案例分析题
import CaseAnalysisQuestionsReal from "@/views/inkQuestionBank/QuestionBankManage/questionType/CaseAnalysisQuestionsReal";
// 组合题(单一题型)
import CombinationQuestion from "@/views/inkQuestionBank/QuestionBankManage/questionType/CombinationQuestion";
// 组合题(复合题型)
import CaseAnalysisQuestions from "@/views/inkQuestionBank/QuestionBankManage/questionType/CaseAnalysisQuestions";


export default {
  name: "QuestionAddOrEdit",
  components: {
    SingleChoiceQuestions,
    MultipleChoiceQuestions,
    Completion,
    Judge,
    ShortAnswer,
    CalculationQuestions,
    EssayQuestion,
    CaseAnalysisQuestionsReal,
    CombinationQuestion,
    CaseAnalysisQuestions
  },
  data() {
    return {
      bankId: '',
      formData: {
        /*题库*/
        stu:'',// stu 1题库  2组合题(单一题型)/组合题(复合题型)  3试卷
        bankId: '',
        bankName: '',
        questionId: '',

        questionType: '10',
        knowledgeId: '',
        difficultyLevel: '10',

        // questionInfo: {}
      },
      // 题目类型下拉
      QuestionTypeList: [],
      // 知识点下拉
      knowledgeIdList: [],
      // 难度下拉
      DegreeDifficultyList: [],
      formDataRules:{
        questionType:[{required: true, message: '请选择题目类型', trigger: 'change'}],
        knowledgeId:[{required: true, message: '请选择知识点', trigger: 'change'}],
        difficultyLevel:[{required: true, message: '请选择难度', trigger: 'change'}],
      }

    };
  },
  computed: {},
  created() {
    this.bankId = this.$route.query.bankId
    // stu 1题库  2组合题(单一题型)/组合题(复合题型)  3试卷
    this.formData.stu = this.$route.query.stu

    if(this.formData.stu == '1'){

      this.formData.bankId = this.$route.query.bankId
      this.formData.bankName = this.$route.query.bankName
      this.formData.questionId = this.$route.query.questionId||undefined

      // this.pageNum = this.$route.query.pageNum
      // this.pageSize = this.$route.query.pageSize
      //
      // this.formData.questionNameStr = this.$route.query.questionNameStr
      // this.formData.questionTypeStr = this.$route.query.questionTypeStr



    }
    // console.log('13',this.formData.questionId)
    this.getKnowledgeList()

    this.getQuestionTypeList()
    this.getDegreeDifficultyList()
    //
    // this.getQuestionInfo(this.$route.query.questionId)
  },
  methods: {
    // 去题目列表页
    toQuestionList(){
      this.$router.push({
        path: "/inkQuestionBank/QuestionBankManage/QuestionList",
        query: {
          bankId: this.formData.bankId,
          bankName: this.formData.bankName,
          pageNum: this.pageNum,
          pageSize: this.pageSize,

          questionNameStr: this.formData.questionNameStr,
          questionTypeStr: this.formData.questionTypeStr,
        }
      });
    },
    // 获取题目详情
    // getQuestionInfo(questionId){
    //   let _this = this
    //   this.$post("/biz/exam/bank/question/getInfo", { questionId },3000,true,6)
    //       .then((ret) => {
    //         if (ret.status == 0) {
    //           //给父组件赋值
    //           this.formData.questionType = ret.data.questionType
    //           this.formData.knowledgeId = ret.data.knowledgeId
    //           this.formData.difficultyLevel = ret.data.difficultyLevel
    //
    //         }
    //       })
    //       .catch((err) => {
    //         return;
    //       });
    // },
    //获取知识点下拉数据
    getKnowledgeList() {
      this.$post("/biz/exam/bank/knowledge/selectable/tree", {"bankId": this.bankId},3000,true,6)
          .then((ret) => {

            if (ret.status == 0) {
              this.knowledgeIdList = this.delNullData(ret.data)
            }
          })
          .catch((err) => {
            return;
          });
    },
    // 去除空数据
    delNullData(list){
      list.forEach((e,i)=>{
        if(e.children.length == 0){
          e.children = undefined
        }else{
          this.delNullData(e.children)
        }
      })
      return list
    },
    //获取测评分类标识--码值
    getQuestionTypeList() {
      const QuestionType = this.$setDictionary("TOOL_QUESTION_TYPE", "list");

      for (const key in QuestionType) {
        this.QuestionTypeList.push({
          value: key,
          label: QuestionType[key],
        });
      }
    },
    //获取测评分类标识--码值
    getDegreeDifficultyList() {
      const DegreeDifficulty = this.$setDictionary("EXAM_QUESTION_DIFFICULTY", "list");
      for (const key in DegreeDifficulty) {
        this.DegreeDifficultyList.push({
          value: key,
          label: DegreeDifficulty[key],
        });
      }
    },
  }
}
</script>

<style lang="less" scoped>

</style>